//@ts-nocheck
import React, {useEffect, useState} from "react"
import AppLayout from "../layout/AppLayout";
import InfoContainer from "../components/containers/InfoContainer";
import styled from "@emotion/styled";
import PrimaryButton, {BUTTON_POSITION} from "../components/buttons/PrimaryButton";
import PowerDonutChart from "../components/charts/PowerDonutChart";
import {useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {CircleLoader} from "react-spinners";
import { TimePicker } from 'react-ios-time-picker';
import {Global, css, useTheme} from "@emotion/react";
import {format, formatISO, parseISO} from 'date-fns'

const ChargeStationInfoContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`

const ChartContainer = styled.div`
  margin: 24px auto 0;
`

const StrategySelection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  margin-top: 8px;
  width: 100%;
`

const StrategyDescription = styled.div`
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.68px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 24px;
`

const StrategySettings = styled.div`
  margin-top: 24px;
`

const EnergySetpointContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 24px;
`

const EnergySetPoint = styled.div`
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 0px;
`

const SelectionButton = styled.button`
  background-color: ${({ theme }) => theme.buttons.activeBackgroundColor};
  color: ${({ theme }) => theme.buttons.activeFontColor};
  width: 48px;
  height: 48px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  vertical-align: middle;
  
  :focus {
    background-color: ${({ theme }) => theme.buttons.activeBackgroundColor};
  }
`

const DepartureContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  
`

const DepartureLabel = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`

const DepartureBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;
  min-height: 48px;
`

const ForecastContainer = styled.div`
  height: 100px;
  width: 100%;
  margin-top: 24px;
`

const ForecastChart = styled.div`
  height: 80px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(0, 0, 100, 0.08);
`

const STATION_QUERY = gql`
    query GetChargingStation($chargingStationId: ID!) {
      getChargingStation(chargingStationId: $chargingStationId) {
        id
        label
        status
        capacity
          evse {
              id
              label
              evseId
              status
              chargingState
              session {
                  chargeStrategy
                  energyChargeTarget
                  departureTime
                  minimumPower
                  startedAt
                  finishedAt
                  sessionId
                  isClaimed
                  isClaimable
              }
              powerForecast
              capabilities {
                  evse {
                      minimumChargeCurrent
                      maximumChargeCurrent
                  }
                  vehicle {
                      minimumChargeCurrent
                      maximumChargeCurrent
                      phases
                  }
              }
              defaults {
                  chargeStrategy
                  sessionDuration
                  targetEnergyRequest
                  minimumPower
              }
              powerManagement {
                  powerInstruction
                  chargeCurrent
                  phasesEnabled
                  physicalPowerConstraints {
                      limit
                      startOfRange
                      endOfRange
                      isAbnormalConditionOnly
                  }
              }
              capacity
              measurements {
                  activePower
                  energyCharged
              }
          }
      }
    }
`

const SET_CHARGE_STRATEGY_MUTATION = gql`
    mutation SetChargeStrategy(
        $chargeStationId: ID!
        $evseId: ID!
        $strategy: ChargeStrategy
    ) {
        setChargeStrategy(
            input: {
                chargeStationId: $chargeStationId,
                evseId: $evseId,
                strategy: $strategy
            }
        )
    }
`

const SET_CHARGE_TARGET = gql`
    mutation SetChargeTarget(
        $chargeStationId: ID!
        $evseId: ID!
        $energyTarget: Int!
        $departureTime: String
    ) {
        setChargeTarget(
            input: {
                chargeStationId: $chargeStationId,
                evseId: $evseId,
                energyTarget: $energyTarget,
                departureTime: $departureTime
            }
        )
    }
`

export default () => {
    const { stationId, evseId } = useParams()
    const theme = useTheme()
    const [ energySetpoint, setEnergySetpoint ] = useState(0)
    const [ departureTime, setDepartureTime] = useState<null | string>(null)
    const { data, loading } = useQuery(STATION_QUERY, {
        variables: {
            chargingStationId: stationId
        },
        fetchPolicy: "no-cache",
        pollInterval: 1000
    })
    const [ setChargeStrategy, { loading: chargeStrategyLoading, data: chargeStrategyData }] = useMutation(SET_CHARGE_STRATEGY_MUTATION)
    const [ setChargeTarget, { loading: chargeTargetLoading, data: chargeTargetData }] = useMutation(SET_CHARGE_TARGET)

    const station = data?.getChargingStation || {}
    // @ts-ignore
    const evse = station.evse?.find(evs => evs.id === evseId) || {}

    useEffect(() => {
        if(evse?.energyChargeTarget) {
            setEnergySetpoint(evse.energyChargeTarget)
        }

        if(evse?.departureTime) {
            setDepartureTime(format(parseISO(evse?.departureTime), "HH:mm"))
        } else {
            setDepartureTime(format(new Date(), "HH:mm"))
        }
    }, [ evse ])

    const updateEnergySetpoint = (diff: number) => {
        if(energySetpoint != 0 || diff > 0) {
            const newEnergySetpoint = energySetpoint + diff
            setEnergySetpoint(newEnergySetpoint)

            const time = departureTime?.split(":")
            const newDepartureTime = new Date().setHours(time[0], time[1], 0)

            setChargeTarget({
                variables: {
                    chargeStationId: stationId,
                    evseId,
                    energyTarget: newEnergySetpoint,
                    departureTime: formatISO(newDepartureTime)
                },
                refetchQueries: [
                    "GetChargingStation"
                ]
            })
        }
    }

    const updateChargeStrategy = (strategy: string) => {
        if(strategy) {
            setChargeStrategy({
                variables: {
                    chargeStationId: stationId,
                    evseId,
                    strategy
                },
                refetchQueries: [
                    "GetChargingStation"
                ]
            })
        }
    }

    const disableStrategyButtons = chargeStrategyLoading

    return (
        <AppLayout>
            <Global styles={css`
              .react-ios-time-picker-input {
                  border: 0;
                  border-radius: 0;
                  font-size: 18px;
                  font-weight: 500;
                  text-align: center;
              }
              
              .react-ios-time-picker-btn {
                color: ${theme.colors.timeSelectorButtonColor};
              }
            `} />
            {loading && <CircleLoader />}
            {!loading &&
            <>
                <ChargeStationInfoContainer>
                    <InfoContainer>{station.label}</InfoContainer>
                    {evse.label && <InfoContainer>{evse.label}</InfoContainer>}
                </ChargeStationInfoContainer>

                <ChartContainer>
                    <PowerDonutChart
                        capacity={evse.capacity}
                        power={evse.measurements.activePower}
                        divideKW={true}
                        name={socketStatusToText(evse.chargingState)}
                    />
                </ChartContainer>

                <StrategyDescription>
                    {evse.activeStrategy === "MAX_POWER" &&
                        <p>Direct laden op een snelheid van 8 tot 11 kW.</p>
                    }
                    {evse.activeStrategy === "CHARGE_TARGET" &&
                        <p>Je auto is op het gekozen moment geladen.</p>
                    }
                    {evse.activeStrategy === "AVAILABLE_POWER" &&
                        <p>Laden op de beschikbare zonne-energie</p>
                    }
                </StrategyDescription>

                <StrategySelection>
                    <PrimaryButton
                        position={BUTTON_POSITION.left}
                        active={evse.activeStrategy === "MAX_POWER"}
                        text="Direct"
                        onClick={() => updateChargeStrategy("MAX_POWER")}
                        disabled={disableStrategyButtons}
                    />

                    <PrimaryButton
                        position={BUTTON_POSITION.middle}
                        active={evse.activeStrategy === "CHARGE_TARGET"}
                        text="Slim"
                        onClick={() => updateChargeStrategy("CHARGE_TARGET")}
                        disabled={disableStrategyButtons}
                    />

                    <PrimaryButton
                        position={BUTTON_POSITION.right}
                        active={evse.activeStrategy === "AVAILABLE_POWER"}
                        text="Zon"
                        onClick={() => updateChargeStrategy("AVAILABLE_POWER")}
                        disabled={disableStrategyButtons}
                    />
                </StrategySelection>

                {/*<ForecastContainer>*/}
                {/*    <ForecastChart>*/}

                {/*    </ForecastChart>*/}
                {/*</ForecastContainer>*/}

                {socket.activeStrategy === "CHARGE_TARGET" &&
                    <StrategySettings>
                        <DepartureLabel>
                            Hoeveel wil je maximaal laden?
                        </DepartureLabel>
                        <EnergySetpointContainer>
                            <SelectionButton onClick={() => updateEnergySetpoint(-1)}>
                                -
                            </SelectionButton>
                            <EnergySetPoint>
                                {energySetpoint} kWh
                            </EnergySetPoint>
                            <SelectionButton onClick={() => updateEnergySetpoint(1)}>
                                +
                            </SelectionButton>
                        </EnergySetpointContainer>

                        <DepartureContainer>
                            <DepartureLabel>
                                Vertrektijd
                            </DepartureLabel>
                            <DepartureBody>
                                {departureTime &&
                                    <TimePicker
                                        pickerDefaultValue={departureTime}
                                        cancelButtonText="Sluiten"
                                        saveButtonText="Opslaan"
                                        placeHolder={departureTime ? departureTime : "Kies een vertrektijd"}
                                        onChange={(value) => setDepartureTime(value)}
                                    />
                                }
                            </DepartureBody>
                        </DepartureContainer>
                    </StrategySettings>
                }

            </>}
        </AppLayout>
    )
}

export const socketStatusToText = (status: string) => {
    switch(status) {
        case "Available":
            return "Beschikbaar";
        case "Pending":
            return "Wachten"
        case "Charging":
            return "Laden"
        case "ThrottledEV":
            return "Beperkt"
        case "SuspendedEV":
        case "SuspendedEVSE":
            return "Gepauzeerd"
        default:
            return "Laden"
    }
}