import React from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ChargeStationSelectionScreen from "./screens/ChargeStationSelectionScreen";
import EvseSelectionScreen from "./screens/EvseSelectionScreen";
import ChargingScreen from "./screens/ChargingScreen";

export default () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ChargeStationSelectionScreen />} />
                <Route path=":stationId/evse" element={<EvseSelectionScreen />} />
                <Route path=":stationId/charging/:evseId" element={<ChargingScreen />} />
            </Routes>
        </BrowserRouter>
    )
}