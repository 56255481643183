import React from "react";
import styled from "@emotion/styled";
import PrimaryButton from "../buttons/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {gql, useQuery, useReactiveVar} from "@apollo/client";
import {customerId} from "../../CustomerThemeProvider";
import {CircleLoader} from "react-spinners";

const Container = styled.div`
`

const ChargersList = styled.ul`
  & li {
    margin-top: 16px;
  }
  
  & li:first-of-type {
    margin-top: 0;
  }
`

const CS_QUERY = gql`
    query GetChargingStationsForCustomer($customerId: ID!) {
      getChargingStationsForCustomer(customerId: $customerId) {
        id
        label
        capacity
        status
        evse {
          id
          label
          session {
            isClaimable
          }
        }
      }
    }
`


export default ({...props}) => {
    const navigate = useNavigate()
    const id = useReactiveVar(customerId)
    const { data, loading } = useQuery(CS_QUERY, {
        variables: {
            customerId: id
        },
        fetchPolicy: "no-cache",
        pollInterval: 5000
    })

    const stations = data?.getChargingStationsForCustomer || []

    return (
        <Container {...props}>
            {loading && <CircleLoader />}
            {!loading &&
                <ChargersList>
                    {/*// @ts-ignore*/}
                    {stations.map(station => {
                        //@ts-ignore
                        const hasClaimableSessions = station.evse?.filter(evse => evse.session.isClaimable === true).length > 0
                            return (
                                <li>
                                    <PrimaryButton
                                        onClick={() => {
                                            navigate(`${station.id}/evse`)
                                        }}
                                        active={hasClaimableSessions}
                                        text={station.label}
                                        disabled={!hasClaimableSessions}/>
                                </li>
                            )
                        }
                    )
                    }
                </ChargersList>
            }
        </Container>
    )
}